<template>
  <div style="width:100%;height:100%" ref="qrcode"></div>
</template>

<script>
import Qrcode from 'qrcodejs2'
export default {
  data() {
    return {
      qrcode: ''
    }
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '200'
    },
    height: {
      type: String,
      default: '200'
    },
    colorDark: {
      type: String,
      default: '#000000'
    },
    colorLight: {
      type: String,
      default: '#ffffff'
    }
  },
  watch: {
    text(newValue) {
      this.createQrcode(newValue)
    }
  },
  mounted() {
    this.createQrcode()
  },
  methods: {
    createQrcode() {
      if(this.qrcode) {
        this.$refs.qrcode.innerHTML = ''
      }
      const options = {
        text: this.text,
        width: this.width,
        height: this.height,
        colorDark: this.colorDark,
        colorLight: this.colorLight,
        correctlevel: Qrcode.CorrectLevel.H
      }
      this.qrcode = new Qrcode(this.$refs.qrcode, options)
    }
  },
}
</script>