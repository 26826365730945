<template>
  <el-popover placement="right" width="200" trigger="click" class="qrcode">
    <qrcode :text="text"></qrcode>
    <el-button slot="reference">商户助手APP下载</el-button>
  </el-popover>
</template>

<script>
import Qrcode from '@/components/qrcode.vue'
export default {
  components: {
    Qrcode
  },
  data() {
    return {
      text: 'https://' + document.location.host + '/client/download'
    }
  }
}
</script>
