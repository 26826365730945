import request from '@/utils/request'

// 列表
const list = (data) => {
  return request.get('article/list', data)
}

// 详情
const info = (id) => {
  return request.get('article/info/' + id)
}

// 搜索
const search = (code) => {
  return request.get('article/search/' + code)
}

export default {
  list,
  info,
  search
}
