<template>
  <section class="header">

    <div class="logo">
      <img src="/images/logo.png" />
    </div>

    <div class="right-menu">
      <download></download>
      <a target="_blank" href="https://merchant.mayijuntuan.com/#/user/signin">
        <el-button type="primary" round>登录</el-button>
      </a>
      <a target="_blank" href="https://merchant.mayijuntuan.com/#/user/signup">
        <el-button round>注册</el-button>
      </a>
    </div>

  </section>
</template>

<script>
import Download from './download.vue'
export default {
  components: {
    Download
  }
}
</script>

<style lang="scss" scoped>
  .header {
    width: 100%;
    height: 69px;

    .logo {
      width: 225px;
      height: 69px;
      float: left;

      img: {
        width: 225px;
        height: 69px;
      }

    }

    .right-menu {
      float: right;
      margin-right: 20px;
      margin-top: 10px;
      height: 100%;
      line-height: 50px;
    }

  }

  .qrcode {
    margin-right: 50px;
  }
</style>
