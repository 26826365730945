import axios from 'axios'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== 0) {
      console.log('code:' + res.code)
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res.data
    }
  },
  error => {
    console.log('err:' + error)
    return Promise.reject(error)
  }
)

const request = {
  getBaseUrl() {
    return service.defaults.baseURL
  },
  get(url, data) {
    return service.get(url, {
      params: data
    })
  },
  post(url, data) {
    return service.post(url, data)
  }
}

export default request
