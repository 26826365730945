/* 文章模块 */
import Layout from '@/layout/empty'

const router = {
  path: '/article',
  component: Layout,
  redirect: '/article/list',
  children: [{
      path: 'list',
      component: () => import('@/views/article/list')
    },
    {
      path: 'detail',
      component: () => import('@/views/article/detail')
    }
  ]
}

export default router
